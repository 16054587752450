import { Countdown } from "../components/Countdown";
import { ReactComponent as AndWriting } from "../svg/and.svg";

import { useIsMobile } from "../util/UseIsMobile";
import { DARK_BLUE_TEXT, HEADER_FONT, PARAGRAPH_FONT } from "../constants";

const MAIN_PHOTO = "/img/ceremony.jpg";

export function HomePage() {
  const isMobile = useIsMobile();

  if (!isMobile) {
    return (
      <div>
        <img
          src={MAIN_PHOTO}
          style={{
            width: "100%",
            objectFit: isMobile ? "cover" : "fill",
            height: isMobile ? "65vh" : "",
          }}
          alt="chris and constance"
        />

        {/* <div style={{ marginTop: 24 }}>
          <span style={{ display: "inline-flex", height: 160 }}>
            <Friday />
          </span>
          <span style={{ display: "inline-flex", height: 160 }}>
            <Saturday />
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 8,
            marginTop: 16,
            marginBottom: 16,
          }}
        >
          <Button variant={"outline-primary"} onClick={() => navigate("/rsvp")}>
            Don't Forget to RSVP!
          </Button>
        </div> */}
      </div>
    );
  }

  return (
    <div>
      <h1
        style={{
          color: DARK_BLUE_TEXT,
          marginTop: 0,
          fontSize: "1.4em",
          fontFamily: HEADER_FONT,
        }}
      >
        CONSTANCE
        <span
          style={{
            color: DARK_BLUE_TEXT,
            marginRight: 10,
            marginLeft: 10,
            verticalAlign: "middle",
            display: "inline-flex",
            width: 50,
          }}
        >
          {" "}
          <AndWriting />{" "}
        </span>
        CHRIS
      </h1>
      <h2
        style={{
          color: DARK_BLUE_TEXT,
          marginBottom: 16,
          marginRight: 20,
          fontSize: "0.9em",
          fontFamily: PARAGRAPH_FONT,
        }}
      >
        September 9th 2023 &nbsp;&nbsp;|&nbsp;&nbsp; Seattle, WA
      </h2>
      <h2
        style={{
          color: DARK_BLUE_TEXT,
          marginTop: 16,
          marginBottom: 16,
          marginRight: 20,
          fontSize: "0.9em",
          fontFamily: PARAGRAPH_FONT,
        }}
      >
        <Countdown date={new Date("9/9/2023 5:00 pm")} />
      </h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: 16,
        }}
      ></div>
      <img
        src={MAIN_PHOTO}
        style={{
          width: "100%",
          objectFit: isMobile ? "cover" : "fill",
          height: isMobile ? "65vh" : "",
        }}
        alt="chris and constance"
      />
      {/* <div style={{ marginTop: 24 }}>
        <span style={{ display: "inline-flex", height: 150 }}>
          <Friday />
        </span>
        <span style={{ display: "inline-flex", height: 150 }}>
          <Saturday />
        </span>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 8,
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <Button variant={"outline-primary"} onClick={() => navigate("/rsvp")}>
          Don't Forget to RSVP!
        </Button>
      </div> */}
    </div>
  );
}
