import { DARK_BLUE_TEXT } from "../constants";

export function HorizontalLine() {
  return (
    <div
      style={{
        backgroundColor: DARK_BLUE_TEXT,
        height: 2,
        flexGrow: 1,
      }}
    ></div>
  );
}
