import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Container from "react-bootstrap/Container";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import {
  FirebaseAppProvider,
  FirestoreProvider,
  StorageProvider,
  useFirebaseApp,
} from "reactfire";

import { AppBar } from "./components/AppBar";
import { AppContext } from "./AppContext";
import { useIsMobile } from "./util/UseIsMobile";
import { AdminPage } from "./pages/AdminPage";
import { HomePage } from "./pages/HomePage";
import { RsvpPage } from "./pages/RsvpPage";
import { RegistryPage } from "./pages/RegistryPage";
import { ThingsToDoPage } from "./pages/ThingsToDoPage";
import { TravelPage } from "./pages/TravelPage";
import { WeddingPage } from "./pages/WeddingPage";
import { PhotosPage } from "./pages/PhotosPage";
import { HoneymoonPage } from "./pages/HoneymoonPage";
import { StoryPage } from "./pages/StoryPage";
import { ReactComponent as FooterLogo } from "./svg/footer.svg";
import { useWindowSize } from "./util/UseWindowSize";

const config = {
  apiKey: "AIzaSyCQOZabXxCRMba64o-KOFWRdJmfKwAEJ6A",
  authDomain: "wedding-18061.firebaseapp.com",
  projectId: "wedding-18061",
  storageBucket: "wedding-18061.appspot.com",
  messagingSenderId: "363019937640",
  appId: "1:363019937640:web:0d3e7927a66e6ed6edd8dd",
  measurementId: "G-7112SZ3JXD",
};

const app = initializeApp(config);
getAnalytics(app);

function App() {
  return (
    <AppContext.Provider value={app}>
      <FirebaseAppProvider firebaseConfig={config}>
        <AppInner />
      </FirebaseAppProvider>
    </AppContext.Provider>
  );
}

function AppInner() {
  const app = useFirebaseApp();
  const firestoreInstance = getFirestore(app);
  const storageInstance = getStorage(app);
  const isMobile = useIsMobile();

  const { height } = useWindowSize();
  const headerHeight = isMobile ? 66 : 248;
  const footerHeight = 70;
  const footerPadding = isMobile ? 0 : 12;

  return (
    <FirestoreProvider sdk={firestoreInstance}>
      <StorageProvider sdk={storageInstance}>
        <header>
          <AppBar />
        </header>
        <main
          style={{
            minHeight:
              (height || 0) - headerHeight - footerHeight - footerPadding,
          }}
        >
          <div
            style={{
              textAlign: "center",
              marginTop: isMobile ? 64 : 8,
            }}
          >
            <Container>
              <Routes>
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/rsvp/:rsvp" element={<RsvpPage />} />
                <Route path="/rsvp" element={<RsvpPage />} />
                <Route path="/festivities" element={<WeddingPage />} />
                <Route path="/things to do" element={<ThingsToDoPage />} />
                <Route path="/travel" element={<TravelPage />} />
                <Route path="/registry" element={<RegistryPage />} />
                <Route path="/photos" element={<PhotosPage />} />
                <Route path="/honeymoon" element={<HoneymoonPage />} />
                <Route path="/story" element={<StoryPage />} />
                <Route path="*" element={<Navigate replace to={`/home`} />} />
              </Routes>
            </Container>
          </div>
        </main>
        <footer>
          <div
            style={{
              height: "50px",
              marginTop: 10,
              marginBottom: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <FooterLogo />
          </div>
        </footer>
      </StorageProvider>
    </FirestoreProvider>
  );
}

export default App;
