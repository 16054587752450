import { useState, useContext } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Cheers } from "../../svg/cheers.svg";

import { RSVP } from "../../models/rsvp";
import { AppContext } from "../../AppContext";

export function FindRsvp() {
  const navigate = useNavigate();
  const app = useContext(AppContext);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>();
  const [pending, setPending] = useState(false);

  if (!app) return null;

  const functions = getFunctions(app);

  const searchForRsvp = (query: string | undefined) => {
    if (!query) {
      setErrorMsg("Please Enter a Name");
      return;
    }

    setPending(true);

    httpsCallable<{ name: string }, { data: RSVP; id: string } | null>(
      functions,
      "findRsvpByName"
    )({
      name: query.trim(),
    })
      .then((val: { data: { data: RSVP; id: string } | null }) => {
        const matchingRsvp = val.data;
        if (matchingRsvp) {
          navigate(`/rsvp/${matchingRsvp.id}`);
        } else {
          setErrorMsg(
            "No RSVP was found. Please make sure you are entering your name as it appears on the invite and are only searching for a single name. If you are having trouble locating your RSVP please email chris.bubernak@gmail.com."
          );
          return;
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  if (pending) return <Spinner animation="border" style={{ marginTop: 24 }} />;

  return (
    <>
      <div>
        <Cheers style={{ width: 150, marginTop: 0, marginBottom: 12 }} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 8,
          marginTop: 24,
          marginBottom: 16,
          alignItems: "baseline",
        }}
      >
        <Form.Control
          placeholder="A name as it appears on invite"
          value={nameSearch}
          onChange={(e) => {
            setErrorMsg(undefined);
            setNameSearch(e.currentTarget.value);
          }}
        />
        <Button onClick={() => searchForRsvp(nameSearch)}>Search</Button>
      </div>
      {errorMsg && (
        <div style={{ color: "red", textAlign: "left" }}>{errorMsg}</div>
      )}
    </>
  );
}
