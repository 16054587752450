import { useEffect, useState } from "react";

export function Countdown(props: { date: Date }) {
  const { date } = props;
  const [curDate, setCurDate] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setCurDate(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const diff = curDate - date.getTime();
  const days = Math.floor(msToDay(diff));

  return (
    <div>
      <b>{days}</b>
      <span>{" days happily married! "}</span>
    </div>
  );
}

function msToDay(ms: number) {
  return ms / (1000 * 60 * 60 * 24);
}
