import { useState, useEffect } from "react";
import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { useStorage } from "reactfire";
import { Spinner } from "react-bootstrap";

const portraitPhotoMap: Record<string, string> = {};
const landscapePhotoMap: Record<string, string> = {};

export function HoneymoonPage() {
  const [index, setIndex] = useState(-1);
  const handleClick = (index: number, item: Image) => setIndex(index);

  const [, setRender] = useState({});
  const [totalPortraitPhotoCount, setTotalPortraitPhotoCount] =
    useState<number>();
  const [totalLandscapePhotoCount, setTotalLandscapePhotoCount] =
    useState<number>();

  const storage = useStorage();
  useEffect(() => {
    listAll(ref(storage, "photos/honeymoon_portrait")).then((res) => {
      setTotalPortraitPhotoCount(res.items.length);
      res.items.forEach((itemRef) => {
        if (!(itemRef.name in portraitPhotoMap)) {
          portraitPhotoMap[itemRef.name] = "";
          getDownloadURL(itemRef).then((val) => {
            portraitPhotoMap[itemRef.name] = val;
            setRender({});
          });
        }
      });
    });

    listAll(ref(storage, "photos/honeymoon_landscape")).then((res) => {
      setTotalLandscapePhotoCount(res.items.length);
      res.items.forEach((itemRef) => {
        if (!(itemRef.name in landscapePhotoMap)) {
          landscapePhotoMap[itemRef.name] = "";
          getDownloadURL(itemRef).then((val) => {
            landscapePhotoMap[itemRef.name] = val;
            setRender({});
          });
        }
      });
    });
  }, [storage]);

  const allPhotos = Object.entries(portraitPhotoMap)
    .map(([key, val]) => {
      return {
        fileName: key,
        src: val || "/img/bg.png",
        width: 1200,
        height: 1600,
      };
    })
    .concat(
      Object.entries(landscapePhotoMap).map(([key, val]) => {
        return {
          fileName: key,
          src: val || "/img/bg.png",
          width: 1600,
          height: 1200,
        };
      })
    );

  const images = allPhotos.sort((a, b) => (a.fileName > b.fileName ? 1 : -1));

  const slides = images.map(({ src }) => ({
    src,
  }));

  if (
    totalPortraitPhotoCount === undefined ||
    totalLandscapePhotoCount === undefined
  )
    return <Spinner animation="border" />;

  return (
    <div>
      <Gallery
        images={images}
        rowHeight={400}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </div>
  );
}
