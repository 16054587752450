import { Row, Col } from "react-bootstrap";
import {
  FaWineGlassAlt,
  FaUtensils,
  FaMountain,
  FaPalette,
  FaHiking,
} from "react-icons/fa";

import { PageHeader } from "../components/PageHeader";
import { HorizontalLine } from "../components/HorizontalLine";
import { TERRACOTTA } from "../constants";
import { useIsMobile } from "../util/UseIsMobile";

interface SectionData {
  title: string;
  link: string;
  desc: string;
}

interface Section {
  header: string;
  items: SectionData[];
  icon: JSX.Element;
}

const FOOD_DATA: SectionData[] = [
  {
    title: "Jamjuree",
    link: "https://www.jamjuree.com/",
    desc: 'No frills Thai spot in Capitol Hill that nails all of the cuisine staples (Chris claims the Pad Kee Mao is the best he\'s ever had.). A very classic "We forgot to cook dinner and need to grab something quick" option for us.',
  },
  {
    title: "Jebena Cafe",
    link: "https://jebenacafe.com/",
    desc: "If you've never had Ethiopan food before consider giving this north Seattle spot a try. The Meat Combo #2 is enough to feed two people and give you a sampling of all the different flavors.",
  },
  {
    title: "Cafe Flora",
    link: "https://cafeflora.com/",
    desc: "This vegetarian cafe is actually our favorite brunch spot in the city. Whether you are feeling savory or sweet (or maybe a little bit of both) you're not going to be disappointed with any of your choices here.",
  },
  {
    title: "The Maple",
    link: "https://www.themaplepub.com/",
    desc: "Maybe we're a little biased as this cozy pub is located just up the street from us but we think this is one of the hidden gems in the city. They nail the pub classics (the fish and chips are incredible) and offer a rotating varitiety of more unique creations like the chicken kofta. An order of the fried brussel sprouts appetizier is also a must!",
  },
  {
    title: "Chili's South Indian Cuisine",
    link: "http://chilissouthindianrestaurant.com/",
    desc: "There is no shortage of amazing Indian food in Seattle but this University district hole in the wall is our favorite. We generally stick to the dosas and curries but they have all the South Indian classics you could ask for.",
  },
  {
    title: "Fogon",
    link: "https://fogonseattle.com/",
    desc: "There are plenty of great spots to get some tacos or a burrito when you're on the go but if you are looking for a place to sit down and enjoy a margarita with some mexican food it's hard to beat Fogon. Chris swears he practically lived off their Chile Colorado for the better part of a year.",
  },
  {
    title: "Windy City Pie",
    link: "https://www.windycitypie.com/",
    desc: "Deep dish pizza isn't everyone's favorite but if a pie that can best be described as Chicago/Detroit fusion sounds like something you would be interested in we highly recommend this spot.",
  },
];

const DRINK_DATA: SectionData[] = [
  {
    title: "Chuck's Hop Shop",
    link: "https://www.chuckshopshop.com/",
    desc: "Low key spot with an extensive (and ever changing) tap list and multiple locations across Seattle - this is our goto for a last minute beer.  They don’t serve food but there is a rotating selection of food trucks that set up shop if you are looking to eat while grabbing a drink.",
  },
  {
    title: "Linda's Tavern",
    link: "https://www.lindastavern.com/",
    desc: "Quintessential Seattle dive bar located in the heart of the Capitol Hill neighboorhood. If you ask Constance there is no better end to a long day out than splitting a pitcher of Manny's out back at Linda's.",
  },
  {
    title: "Canon",
    link: "https://www.canonseattle.com/",
    desc: "Upscale cocktail bar known for the huge selection of spirits and highly creative cocktails. Be warned though this is a popular spot and you'll probably want to get your name on the list early in the night if you want to get in.",
  },
  {
    title: "Ballard Brewery District",
    link: "https://www.visitballard.com/craftbrews/",
    desc: "Admittedly a bit of a cop out but if you're looking to spend the afternoon at a Seattle brewery why not head over to Ballard where you can have your choice of more than a dozen craft breweries all within a stones throw of each other. Urban Family, Stoup, and Peddler are a few of our personnel favorites but you really can't go wrong with any of your options here.",
  },
];

const CULTURE_DATA: SectionData[] = [
  {
    title: "Chihuly Garden and Glass",
    link: "https://www.chihulygardenandglass.com/",
    desc: "An entire museum dedicated to the blown glass art stylings of Dale Chihuly. He is best known for his large scale installations that are really quite stunning to witness if you've never seen them before in person. Pro Tip: If you're planning on going to the top of the Space Needle you can get a bundle pass to see the musem as well.",
  },
  {
    title: "MoPOP",
    link: "https://www.mopop.org/",
    desc: "The Museum of Pop Culture is located in the heart of Seattle right next to the Space Needle. It's full of interactive exhibits that make it a great way to spend an afternoon for kids and adults alike.",
  },
];

const OUTDOORS_DATA: SectionData[] = [
  {
    title: "Pike Place Market",
    link: "https://www.pikeplacemarket.org/",
    desc: "Spending time at this iconic market has to be #1 on any list of Seattle tourist attractions. Located in the heart of downtown you're surrounded by food, drink, and just a short walk from the waterfront. A required stop if you've never been to the city before.",
  },
  {
    title: "Discovery Park",
    link: "https://www.seattle.gov/parks/find/parks/discovery-park",
    desc: 'Nearly 12 miles of walking trails that take you from the city, through a forrest, down to the beach. One of the best ways to "get away" from the city without actually leaving.',
  },
  {
    title: "Green Lake",
    link: "https://www.seattle.gov/parks/find/parks/green-lake-park",
    desc: "Located near the University of Washington this has to be one of the most popular spots in the city to go for a run or just a morning stroll. We're out here nearly every weekend in the morning grabbing a coffee and taking a walk around the lake with Ernie.",
  },
  {
    title: "Auga Verde Paddle Club",
    link: "https://aguaverdepaddleclub.com/",
    desc: "There is nothing more Seattle than spending time out on the water. One of our favorite options, especially on a warm summer day, is to rent kayaks and paddle around Lake Washington.",
  },
  {
    title: "Gas Works Park",
    link: "https://seattle.gov/parks/allparks/gas-works-park",
    desc: "Great spot for picnic that offers great views of the city! From here you can enjoy walking and exploring Fremont. Don't miss the Fremont Troll!",
  },
];

const HIKING_DATA: SectionData[] = [
  {
    title: "Mt. Si Trailhead",

    link: "https://www.wta.org/go-hiking/hikes/mount-si",
    desc: "Classic and difficult Seattle hike. It is quite close to the city, 3000ft of gain but the views at the top are great!",
  },
  {
    title: "Rattlesnake Ledge Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/rattlesnake-ledge",
    desc: "Classic and much easier Seattle hike. Constance loves to do this one after work in the summertime. It is also where some of the engagement photos were taken! You'll start at a lake and end great views over Rattlesnake ledge.",
  },
  {
    title: "Lake Twenty-Two Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/lake-22-lake-twenty-two",
    desc: "Constance usually does this one in the winter! This is a great alpine lake hike that is not too long or too hard. You'll get great rainforest vibes at the start and the lake at the end is very pretty.",
  },
  {
    title: "Lake Serene Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/lake-serene",
    desc: "This hike has both a beautiful alpine lake and waterfall. A very popular hike for the area. This was one of the first hikes both Chris and Constance when they got to Seattle. If it's hot it's a fantastic swimming spot!",
  },
  {
    title: "Rachel Lake Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/rachel-lake\nhttps://www.wta.org/go-hiking/hikes/rampart-ridge-1",
    desc: "This is a hard one, and even harder if you combine it with Rampart Lakes. It's a grind up with lots of rocks steps but the lake is really amazing and so blue. Rampart Lakes are another 600ft of gain but you'll get to see a pretty collection of pothole lakes.",
  },
  {
    title: "Franklin Falls Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/franklin-falls",
    desc: "Want to experience the beauty of Washington state but don't really want to hike? Go to Franklin Falls! The drive to Snoqualmie Pass is great. And then you'll get an easy and beautiful path to the impressive falls.",
  },
  {
    title: "Tolmie Peak Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/tolmie-peak",
    desc: "If you want Rainier view, THIS IS IT! It is a truly spectacular hike with two lakes and incredible views. It's a bit of a drive but it is worth it! Probably the best reward to effort you can get :)",
  },
  {
    title: "Maple Pass Trailhead",
    link: "https://www.wta.org/go-hiking/hikes/maple-pass",
    desc: "Best hike in the North Cascade National Park! This loop has fantastic view basically the entire way around. Really stunning views of the mountain ranges. It's a long drive but along the way you'll see the baby blue Diablo Lake. It is worth the drive!",
  },
];

const sections: Section[] = [
  {
    header: "Food",
    items: FOOD_DATA,
    icon: <FaUtensils style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Drinks",
    items: DRINK_DATA,
    icon: <FaWineGlassAlt style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Culture",
    items: CULTURE_DATA,
    icon: <FaPalette style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Outdoors",
    items: OUTDOORS_DATA,
    icon: <FaMountain style={{ width: 24, height: 24 }} />,
  },
  {
    header: "Hiking",
    items: HIKING_DATA,
    icon: <FaHiking style={{ width: 24, height: 24 }} />,
  },
];

export function ThingsToDoPage() {
  const isMobile = useIsMobile();
  const iconTopMargin = isMobile ? 0 : 4;
  return (
    <div>
      <PageHeader text={"Things To Do"} />
      <iframe
        title="constance fill this in"
        src="https://www.google.com/maps/d/embed?mid=1sMASZn4vOQa3Ahsj2LsNEBlqodjwcj8&hl=en&ehbc=2E312F"
        width="90%"
        height="550"
      ></iframe>
      {sections.map((s) => (
        <Row
          key={s.header}
          className="justify-content-md-center"
          style={{ marginTop: 24 }}
        >
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={12}
            style={{ textAlign: "left", display: "flex" }}
          >
            <span style={{ marginTop: iconTopMargin, marginRight: 8 }}>
              {s.icon}
            </span>
            <h3>{s.header}</h3>
          </Col>
          <Col sm={8} xs={12} md={8} lg={7}>
            {s.items.map((item, i) => {
              return (
                <div
                  key={i}
                  style={{ textAlign: "left", marginTop: 8, marginBottom: 8 }}
                >
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: TERRACOTTA }}
                  >
                    {item.title}
                  </a>
                  <p>{item.desc}</p>
                </div>
              );
            })}
          </Col>
          <Col xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
            <HorizontalLine />
          </Col>
        </Row>
      ))}
    </div>
  );
}
