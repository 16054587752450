import { useParams } from "react-router-dom";

import { PageHeader } from "../components/PageHeader";
import { FindRsvp } from "../components/rsvp/FindRsvp";
import { RsvpForm } from "../components/rsvp/RsvpForm";

export function RsvpPage() {
  const { rsvp: rsvpId } = useParams();

  return (
    <div>
      <PageHeader text={"RSVP"} />
      <div style={{ maxWidth: 400, margin: "0 auto" }}>
        {rsvpId ? <RsvpForm id={rsvpId} /> : <FindRsvp />}
      </div>
    </div>
  );
}
