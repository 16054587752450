import { useState } from "react";
import { Button, Form, Spinner, ToggleButton } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { ReactComponent as Cheers } from "../../svg/cheers.svg";

import { itemsAsList } from "../../util/format";
import { RSVP } from "../../models/rsvp";

enum RSVPStatus {
  unknown = 0,
  yes = 1,
  no = -1,
}

export function RsvpForm(props: { id: string }) {
  const { id } = props;
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [verified, setVerified] = useState(false);

  const docRef = doc(firestore, "rsvp", id);
  const { status, data } = useFirestoreDocData(docRef);

  if (status === "loading") {
    return <Spinner animation="border" style={{ marginTop: 24 }} />;
  }

  const inProgressRSVP = data as RSVP;

  if (verified) {
    return <InnerForm inProgressRSVP={inProgressRSVP} id={id} />;
  } else {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 12,
        }}
      >
        <div>
          <Cheers style={{ width: 150, marginTop: 0, marginBottom: 32 }} />
        </div>
        <div style={{ textAlign: "left" }}>
          <h4>{itemsAsList(inProgressRSVP.invites.map((i) => i.name))}</h4>
        </div>
        <div style={{ textAlign: "left" }}>
          We can’t wait to celebrate our marriage with you! Whether that be on
          Sept 9th or after. ​
        </div>
        <Button
          style={{ alignSelf: "center", width: 300 }}
          onClick={() => setVerified(true)}
        >
          {inProgressRSVP.hasSubmitted
            ? "Update my RSVP"
            : "Let's Get Started!"}
        </Button>
        <Button
          style={{ alignSelf: "center", width: 300 }}
          variant="secondary"
          onClick={() => navigate("/rsvp")}
        >
          That's not me! Try again!
        </Button>
      </div>
    );
  }
}

function InnerForm(props: { inProgressRSVP: RSVP; id: string }) {
  const { id } = props;
  const firestore = useFirestore();
  const [statusMsg, setStatusMsg] = useState<{ error: Boolean; msg: string }>();
  const [inProgressRSVP, setInProgressRSVP] = useState<RSVP>({
    ...props.inProgressRSVP,
    // Update this to true in case it hasn't yet.
    hasSubmitted: true,
  });

  if (!inProgressRSVP.hasViewed) {
    const docRef = doc(firestore, "rsvp", id);
    setDoc(docRef, { hasViewed: true }, { merge: true })
      .then((res) => {
        console.log("Marked as viewed.");
      })
      .catch((e) => {
        console.log("Failed to mark as viewed.");
      });
    // Update the object so we don't keep coming in here.
    setInProgressRSVP({ ...inProgressRSVP, hasViewed: true });
  }

  const onSubmit = (rsvp: RSVP) => {
    const docRef = doc(firestore, "rsvp", id);
    setDoc(docRef, rsvp)
      .then((res) => {
        setStatusMsg({
          error: false,
          msg: "Your RSVP was successully recorded!",
        });
      })
      .catch((e) => {
        setStatusMsg({
          error: true,
          msg: "There was an error recording your RSVP, please email chris.bubernak@gmail.com.",
        });
      });
  };

  const showDietAndMusic = inProgressRSVP.invites.some(
    (i) => i.status === RSVPStatus.yes
  );

  if (statusMsg) {
    return (
      <>
        <div>
          <Cheers style={{ width: 150, marginTop: 0, marginBottom: 32 }} />
        </div>
        <div style={{ color: statusMsg.error ? "red" : "" }}>
          {statusMsg.msg}
        </div>
      </>
    );
  }
  return (
    <Form style={{ marginTop: 24, marginBottom: 24 }}>
      {inProgressRSVP.invites.map((invite, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
            marginTop: 8,
          }}
        >
          <span>{invite.name}</span>
          <div style={{ display: "flex", gap: 8 }}>
            {[RSVPStatus.yes, RSVPStatus.no].map((val, idx) => (
              <ToggleButton
                key={idx}
                id={`invite-${i}-${val}`}
                type="radio"
                variant="outline-primary"
                value={val}
                onClick={() => {
                  const copy = copyObj(inProgressRSVP);
                  copy.invites[i].status = val;
                  setInProgressRSVP(copy);
                }}
                checked={val === inProgressRSVP.invites[i].status}
              >
                {val === RSVPStatus.yes ? "Accept" : "Decline"}
              </ToggleButton>
            ))}
          </div>
        </div>
      ))}
      {inProgressRSVP.allowedGuest && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 8,
            marginBottom: 8,
            marginTop: 8,
          }}
        >
          <Form.Control
            onChange={(e) => {
              const copy = copyObj(inProgressRSVP);
              copy.guest.name = e.currentTarget.value;
              setInProgressRSVP(copy);
            }}
            value={inProgressRSVP.allowedGuest ? inProgressRSVP.guest.name : ""}
            disabled={inProgressRSVP.guest.status === RSVPStatus.no}
            placeholder="Special Guest Name"
          />
          <div style={{ display: "flex", gap: 8 }}>
            {[RSVPStatus.yes, RSVPStatus.no].map((val, idx) => (
              <ToggleButton
                key={idx}
                id={`guest-${val}`}
                type="radio"
                variant="outline-primary"
                value={val}
                onClick={() => {
                  const copy = copyObj(inProgressRSVP);
                  copy.guest.status = val;
                  setInProgressRSVP(copy);
                }}
                checked={val === inProgressRSVP.guest.status}
              >
                {val === RSVPStatus.yes ? "Accept" : "Decline"}
              </ToggleButton>
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: 16,
          gap: 16,
        }}
      >
        {showDietAndMusic && (
          <>
            <div>
              <b>Dietary Restrictions</b>
              <Form.Control
                onChange={(e) => {
                  const copy = copyObj(inProgressRSVP);
                  copy.diet = e.currentTarget.value;
                  setInProgressRSVP(copy);
                }}
                as="textarea"
                rows={3}
                placeholder={
                  "Food will be buffet style with a range of options but if you have any serious allergies or dietary restrictions please let us know. ​"
                }
              />
            </div>
            <div>
              <b>Song Requests</b>
              <Form.Control
                onChange={(e) => {
                  const copy = copyObj(inProgressRSVP);
                  copy.music = e.currentTarget.value;
                  setInProgressRSVP(copy);
                }}
                as="textarea"
                rows={3}
                placeholder={"Any song requests to get you on the dance floor?"}
              />
            </div>
            <div>
              <b>Welcome Celebration</b>
              <p style={{ marginBottom: 8 }}>
                We will be hosting an optional welcome celebration on Friday
                Sept 8th at Alki Beach. Please let us know if you are
                considering attending so we can plan accordingly for food and
                drinks.
              </p>
              <div
                style={{ display: "flex", gap: 8, justifyContent: "center" }}
              >
                {[RSVPStatus.yes, RSVPStatus.no].map((val, idx) => (
                  <ToggleButton
                    key={idx}
                    type="radio"
                    variant="outline-primary"
                    value={val}
                    onClick={() => {
                      const copy = copyObj(inProgressRSVP);
                      copy.welcome = val;
                      setInProgressRSVP(copy);
                    }}
                    checked={val === inProgressRSVP.welcome}
                  >
                    {val === RSVPStatus.yes ? "Accept" : "Decline"}
                  </ToggleButton>
                ))}
              </div>
            </div>
          </>
        )}
        <div>
          <b>Send us a Note</b>
          <Form.Control
            onChange={(e) => {
              const copy = copyObj(inProgressRSVP);
              copy.note = e.currentTarget.value;
              setInProgressRSVP(copy);
            }}
            as="textarea"
            rows={3}
            placeholder={"Well wishes, top marriage advice, etc."}
          />
        </div>
        <Button onClick={() => onSubmit(inProgressRSVP)}>Submit</Button>
      </div>
    </Form>
  );
}

function copyObj<T>(obj: T) {
  return JSON.parse(JSON.stringify(obj)) as T;
}
