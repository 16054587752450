import { Link, useLocation } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { ReactComponent as AndWriting } from "../svg/and.svg";
import { ReactComponent as CCLogo } from "../svg/CCLogo.svg";
import { Countdown } from "../components/Countdown";

import { useIsMobile } from "../util/UseIsMobile";
import {
  DARK_BLUE_TEXT,
  TERRACOTTA,
  HEADER_FONT,
  PARAGRAPH_FONT,
  CREAM,
  PEACH,
  CREAM_BACKGROUND,
} from "../constants";
import { HorizontalLine } from "./HorizontalLine";

export function AppBar() {
  const isMobile = useIsMobile();
  const location = useLocation();
  const active = location.pathname.split("/")[1];

  const tabs = [
    "home",
    //"rsvp",
    //"festivities",
    "photos",
    "honeymoon",
    "things to do",
    //"registry",
  ];

  if (!isMobile) {
    return (
      <Container style={{ textAlign: "center" }}>
        <h1
          style={{
            color: DARK_BLUE_TEXT,
            fontSize: "2.8em",
            fontFamily: HEADER_FONT,
          }}
        >
          CONSTANCE
          <span
            style={{
              color: DARK_BLUE_TEXT,
              marginTop: 8,
              marginBottom: 8,
              marginRight: 20,
              marginLeft: 20,
              fontWeight: 100,
              verticalAlign: "middle",
              display: "inline-flex",
              width: 100,
            }}
          >
            {" "}
            <AndWriting />{" "}
          </span>
          CHRIS
        </h1>
        <h2
          style={{
            color: DARK_BLUE_TEXT,
            marginBottom: 24,
            fontWeight: 75,
            fontSize: "1.2em",
            fontFamily: PARAGRAPH_FONT,
          }}
        >
          September 9th 2023 &nbsp;&nbsp;|&nbsp;&nbsp; Seattle, WA
        </h2>
        <h2
          style={{
            color: DARK_BLUE_TEXT,
            marginBottom: 32,
            fontSize: "1.2em",
          }}
        >
          <Countdown date={new Date("9/9/2023 5:00 pm")} />
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 16,
          }}
        >
          <HorizontalLine />
          <div>
            {tabs.map((t) => (
              <Link
                key={t}
                to={`/${t}`}
                style={{
                  display: "inline",
                  color: active === t ? TERRACOTTA : PEACH,
                  padding: 8,
                  marginLeft: 8,
                  marginRight: 8,
                  backgroundColor: active === t ? CREAM : "",
                  textTransform: "uppercase",
                  textDecoration: "none",
                  fontWeight: 600,
                }}
              >
                {t}
              </Link>
            ))}
          </div>
          <HorizontalLine />
        </div>
      </Container>
    );
  }

  return (
    <Navbar
      expand={false}
      fixed="top"
      collapseOnSelect={true}
      style={{ background: CREAM_BACKGROUND }}
    >
      <Container>
        <Navbar.Brand href="/home">
          <CCLogo style={{ height: 40, width: 40, marginRight: "-8px" }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar" />
        <Navbar.Collapse id="navbar">
          <Nav className="me-auto">
            {tabs.map((t) => (
              <Nav.Link
                key={t}
                as={Link}
                to={`/${t}`}
                href={`/${t}`}
                style={{ textTransform: "uppercase", color: DARK_BLUE_TEXT }}
              >
                {t}
              </Nav.Link>
            ))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
