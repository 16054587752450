export function moneyFormat(num: number) {
    return num.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
}

export function itemsAsList(items: string[]) {
  if (items.length === 1) {
    return items[0];
  }
  return `${items.slice(0, -1).join(", ")} and ${items[items.length - 1]}`;
}
