import { useState, useEffect } from "react";
import { Gallery, Image } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { useStorage } from "reactfire";
import { Button, Spinner } from "react-bootstrap";

const portraitPhotoMap: Record<string, string> = {};
const landscapePhotoMap: Record<string, string> = {};

export function PhotosPage() {
  const [index, setIndex] = useState(-1);
  const handleClick = (index: number, item: Image) => setIndex(index);

  const [, setRender] = useState({});
  const [totalPortraitPhotoCount, setTotalPortraitPhotoCount] =
    useState<number>();
  const [totalLandscapePhotoCount, setTotalLandscapePhotoCount] =
    useState<number>();

  const storage = useStorage();
  useEffect(() => {
    listAll(ref(storage, "photos/wedding_portrait")).then((res) => {
      setTotalPortraitPhotoCount(res.items.length);
      res.items.forEach((itemRef) => {
        if (!(itemRef.name in portraitPhotoMap)) {
          portraitPhotoMap[itemRef.name] = "";
          getDownloadURL(itemRef).then((val) => {
            portraitPhotoMap[itemRef.name] = val;
            setRender({});
          });
        }
      });
    });

    listAll(ref(storage, "photos/wedding_landscape")).then((res) => {
      setTotalLandscapePhotoCount(res.items.length);
      res.items.forEach((itemRef) => {
        if (!(itemRef.name in landscapePhotoMap)) {
          landscapePhotoMap[itemRef.name] = "";
          getDownloadURL(itemRef).then((val) => {
            landscapePhotoMap[itemRef.name] = val;
            setRender({});
          });
        }
      });
    });
  }, [storage]);

  const allPhotos = Object.entries(portraitPhotoMap)
    .map(([key, val]) => {
      return {
        src: val || "/img/bg.png",
        width: 1065,
        height: 1600,
      };
    })
    .concat(
      Object.entries(landscapePhotoMap).map(([key, val]) => {
        return {
          src: val || "/img/bg.png",
          width: 1600,
          height: 1065,
        };
      })
    );

  const images = shuffle(
    allPhotos.sort((a, b) => (a.src > b.src ? 1 : -1)),
    421
  );

  const slides = images.map(({ src }) => ({
    src,
  }));

  if (
    totalPortraitPhotoCount === undefined ||
    totalLandscapePhotoCount === undefined
  )
    return <Spinner animation="border" />;

  return (
    <div>
      <Gallery
        images={images}
        rowHeight={400}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
      <a
        href="https://clientgallery.soundandseaphotography.com/-constancechriswedding"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          style={{ marginTop: 16, marginBottom: 16 }}
          variant={"outline-primary"}
        >
          See Full Album
        </Button>
      </a>
    </div>
  );
}

function shuffle<T>(array: T[], seed: number) {
  let m = array.length,
    t,
    i;

  // While there remain elements to shuffle.
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--);

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed;
  }

  return array;
}

function random(seed: number) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}
