import { Row, Col } from "react-bootstrap";

import { PageHeader } from "../components/PageHeader";
import { DARK_BLUE_TEXT, TERRACOTTA } from "../constants";
import { FaBus, FaCar } from "react-icons/fa";
import { useIsMobile } from "../util/UseIsMobile";
import { ReactNode } from "react";

interface ScheduleEntry {
  time: number; // Time in minutes.
  event: string;
}

const WEDDING_DATE = Date.parse("09/09/2022");

const timeToStr = (min: number) => {
  const time = WEDDING_DATE + min * 60 * 1000;
  const timeStr = new Date(time).toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  return timeStr;
};

interface Section {
  header: string;
  items: ReactNode[];
  icon: JSX.Element;
}

const withinSodoSections: Section[] = [
  {
    header: "By Car",
    items: [
      "Venue entrance is on Utah St. Within Sodo is located in a very industrial part of town, you'll find lots of parking on Utah street. There may be private businesses No Parking signs but they are not enforced on weekends. The venue recommends parking there as it is a Saturday.",
    ],
    icon: <FaCar style={{ width: 24, height: 24 }} />,
  },
  {
    header: "By Transit",
    items: [
      "Within Sodo is 15 min walking distance (0.7 miles/ 1.2 km) away from the SODO Seattle Light Rail stop. If you are staying Downtown or Capital Hill the light rail may be a great option!",
    ],
    icon: <FaBus style={{ width: 24, height: 24 }} />,
  },
];

const alkiSections: Section[] = [
  {
    header: "By Car",
    items: [
      "Now that the West Seattle bridge has re-opened, getting to the beach via car is going to be the easiest option. Parking can become a bit of hassle on a nice day but if you're unable to get lucky with a spot there is always parking available as you head away from the beach and into the surrounding neighborhoods.",
    ],
    icon: <FaCar style={{ width: 24, height: 24 }} />,
  },
  {
    header: "By Transit",
    items: [
      <span>
        If you are staying near Downtown Seattle a classic experience is to take
        the{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://kingcounty.gov/depts/transportation/water-taxi/west-seattle.aspx"
          style={{ color: DARK_BLUE_TEXT }}
        >
          water taxi
        </a>{" "}
        from Pier 50 on the waterfront out to West Seattle. The boat drops you
        off about 2 miles (3.2 km) from the beach but there is a free shuttle
        (Route 775) that will take you the rest of the way. More information can
        be found{" "}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://kingcounty.gov/~/media/depts/transportation/water-taxi/king-county-water-taxi-brochure.ashx?la=en"
          style={{ color: DARK_BLUE_TEXT }}
        >
          here
        </a>
        .
      </span>,
    ],
    icon: <FaBus style={{ width: 24, height: 24 }} />,
  },
];

const schedule: ScheduleEntry[] = [
  { time: 16 * 60 + 30, event: "Doors Open" },
  { time: 17 * 60, event: "Ceremony" },
  { time: 17 * 60 + 30, event: "Cocktail Hour" },
  { time: 19 * 60, event: "Dinner and Dancing" },
  { time: 23 * 60, event: "Late Night Snack" },
  { time: 25 * 60, event: "End of the Evening" },
];

export function WeddingPage() {
  const isMobile = useIsMobile();
  const iconTopMargin = isMobile ? 0 : 4;

  return (
    <div>
      <PageHeader text={"Wedding Day"} />
      <h2 style={{ marginTop: 0, marginBottom: 24, fontSize: 20 }}>
        September 9th, 2023
      </h2>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap-reverse",
          justifyContent: "center",
          columnGap: 48,
        }}
      >
        <span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/dir//WithinSodo,+2916+Utah+Ave+S,+Seattle,+WA+98134/@47.577278,-122.3474964,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5490419b2aa64695:0xa65e1891b4b5137e!2m2!1d-122.3349043!2d47.5771676!3e0"
          >
            <img
              src={"/img/MapVenue.png"}
              style={{
                width: 200,
                height: 200,
                filter: "grayscale(100%)",
              }}
              alt="Map to Within Sodo"
            />
          </a>
        </span>
        <span style={{ textAlign: "left" }}>
          <h2 style={{ fontSize: 26 }}>Within Sodo</h2>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.google.com/maps/dir//WithinSodo,+2916+Utah+Ave+S,+Seattle,+WA+98134/@47.577278,-122.3474964,15z/data=!4m9!4m8!1m0!1m5!1m1!1s0x5490419b2aa64695:0xa65e1891b4b5137e!2m2!1d-122.3349043!2d47.5771676!3e0"
            style={{ color: DARK_BLUE_TEXT }}
          >
            <h3 style={{ fontSize: 16 }}>2916 Utah Ave S, Seattle, WA 98134</h3>
          </a>
          <h3 style={{ fontSize: 16 }}>Attire: Semi-Formal</h3>
          <h3 style={{ fontSize: 16 }}>4:30PM - 1:00 AM</h3>
        </span>
      </div>
      {/* <Row className="justify-content-center">
        <Col sm={12} xs={12} md={9} lg={7}>
          <h3 style={{ fontSize: 16, margin: "auto", marginTop: 24 }}>
            We are so excited to invite to celebrate our wedding with us. Our
            wedding will be a fun and relaxed affair, with delicious food, great
            music, and lots of dancing. We hope you can join us and share in our
            joy. Please RSVP by July 21st. Thank you for being part of our lives
            and our big day!
          </h3>
        </Col>
      </Row> */}
      <h2 style={{ marginTop: 12, fontSize: 26 }}>Schedule</h2>
      <table style={{ margin: "0 auto", marginTop: 24, marginBottom: 24 }}>
        <tbody>
          {schedule.map((d, i) => (
            <tr>
              <td
                style={{
                  borderRight: `2px solid ${DARK_BLUE_TEXT}`,
                  textAlign: "left",
                  paddingLeft: 64,
                  paddingRight: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                  color: TERRACOTTA,
                }}
              >
                {timeToStr(d.time)}
              </td>
              <td
                style={{
                  border: "none",
                  textAlign: "left",
                  paddingLeft: 24,
                  paddingTop: 16,
                  paddingBottom: 16,
                  color: TERRACOTTA,
                }}
              >
                <b>{d.event}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <h2 style={{ marginTop: 12, fontSize: 26 }}>Getting There</h2>
      <div
        style={{ justifyContent: "center", marginLeft: 12, marginRight: 12 }}
      >
        {withinSodoSections.map((s) => (
          <Row
            className="justify-content-md-center"
            key={s.header}
            style={{ marginTop: 8 }}
          >
            <Col
              lg={2}
              md={3}
              sm={4}
              xs={12}
              style={{
                textAlign: "left",
                display: "flex",
                alignItems: "inherit",
              }}
            >
              <span style={{ marginTop: iconTopMargin, marginRight: 8 }}>
                {s.icon}
              </span>
              <h3 style={{ fontSize: "24px" }}>{s.header}</h3>
            </Col>
            <Col sm={8} xs={12} md={8} lg={7}>
              {s.items.map((item, i) => {
                return (
                  <div
                    key={i}
                    style={{ textAlign: "left", marginTop: 0, marginBottom: 8 }}
                  >
                    {item}
                  </div>
                );
              })}
            </Col>
          </Row>
        ))}
      </div>
      <div style={{ marginTop: 48 }}>
        <PageHeader text={"Welcome Celebration"} />
        <h2 style={{ marginTop: 0, marginBottom: 24, fontSize: 20 }}>
          September 8th, 2023
        </h2>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap-reverse",
            justifyContent: "center",
            columnGap: 48,
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          <Row className="justify-content-center">
            <Col sm={12} xs={12} md={9} lg={7}>
              <div
                style={{
                  fontSize: 16,
                  margin: "auto",
                  marginTop: 24,
                  textAlign: "left",
                }}
              >
                We are so lucky to have friends and family coming in from near
                and far. As many have travelled into Seattle, please join us for
                a casual welcome beach party. We'll have a number of picnic
                tables reserved just southwest of the bathhouse (between 61st
                and 62nd ave). There will be food, beverages, lawn games and,
                weather permitting, some firepits.{" "}
              </div>
            </Col>
          </Row>
          <span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/47%C2%B034'45.1%22N+122%C2%B024'40.9%22W/@47.579196,-122.412415,18z/data=!3m1!4b1!4m4!3m3!8m2!3d47.579196!4d-122.411354?entry=ttu"
            >
              <img
                src={"/img/Alki.png"}
                style={{
                  width: 200,
                  height: 200,
                  filter: "grayscale(100%)",
                }}
                alt="Map to Alki Beach"
              />
            </a>
          </span>
          <span style={{ textAlign: "left" }}>
            <h2> Alki Beach</h2>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.google.com/maps/place/47%C2%B034'45.1%22N+122%C2%B024'40.9%22W/@47.579196,-122.412415,18z/data=!3m1!4b1!4m4!3m3!8m2!3d47.579196!4d-122.411354?entry=ttu"
              style={{ color: DARK_BLUE_TEXT }}
            >
              <h3 style={{ fontSize: 16 }}>
                2701 Alki Ave SW, Seattle, WA 98116
              </h3>
            </a>
            <h3 style={{ fontSize: 16 }}> Attire: Casual</h3>
            <h3 style={{ fontSize: 16 }}> 4:00PM - 8:00 PM</h3>
          </span>
        </div>
        <h2 style={{ marginTop: 12 }}>Getting There</h2>
        <div
          style={{ justifyContent: "center", marginLeft: 12, marginRight: 12 }}
        >
          {alkiSections.map((s) => (
            <Row
              className="justify-content-md-center"
              key={s.header}
              style={{ marginTop: 8 }}
            >
              <Col
                lg={2}
                md={3}
                sm={4}
                xs={12}
                style={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "inherit",
                }}
              >
                <span style={{ marginTop: iconTopMargin, marginRight: 8 }}>
                  {s.icon}
                </span>
                <h3 style={{ fontSize: "24px" }}>{s.header}</h3>
              </Col>
              <Col sm={8} xs={12} md={8} lg={7}>
                {s.items.map((item, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        textAlign: "left",
                        marginTop: 0,
                        marginBottom: 8,
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </div>
  );
}
