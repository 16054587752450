// Colors.
export const LIGHT_GRAY = "#B1B1B1";
export const LIGHT_GRAY_BACKGROUND = "#F7F7F7";
export const DARK_GRAY = "#404040";
export const DARK_BLUE = "#081E50";
export const DUSTY_BLUE = "#73819C";
export const TERRACOTTA = "#B85657";
export const PEACH = "#C69EA1";
export const CREAM = "#F4EEEA";
export const CREAM_BACKGROUND = "#F7F6F5";
export const DARK_BLUE_TEXT = "#003153";

// Fonts.
export const HEADER_FONT = "MonCheri";
export const PARAGRAPH_FONT = "Marcellus";
export const AND_FONT = "WindSong";
